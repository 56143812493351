/**
* Button Component
* Created by Emmanuella Albuquerque on 2023/03/16. Teste de conta
*/

import './styles.css';

export function Button({ redirect, title }) {
  return (
    <a href={redirect} target="_blank" 
      rel="noopener noreferrer"
      className="button"
    >
      {title}
    </a>
  );
}
