import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import './styles.css';
import './basic-sidebar-style.css';

export default function Sidebar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  }

  const closeMenu = () => {
    setMenuOpen(false);
  }

  return (
    <Menu 
      right 
      isOpen={menuOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <a href="#how-to-cite" className="menu-item" onClick={closeMenu}>
        How to cite
      </a>

      <a href="#about" className="menu-item" onClick={closeMenu}>
        About
      </a>

      <a href="#team" className="menu-item" onClick={closeMenu}>
        Team
      </a>

      <a href="#contact" className="menu-item" onClick={closeMenu}>
        Contact
      </a>

      <span className="menu-item" onClick={closeMenu}>
        Help
      </span>
    </Menu>
  );
};
