/**
* How to cite Template
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

export function Howtocite() {
  return (
    <p>
    M. Tullius Scotti, C. Herrera-Acevedo, R. P. Barros de Menezes, H.-J. Martin, E. N. Muratov, Á. Ítalo de Souza Silva, E. Faustino Albuquerque, L. Ferreira Calado, E. Coy-Barrera, L. Scotti., Mol. Inf. 2022, 41, 2200133.
    <br/>
    <br/>

      <a href="https://doi.org/10.1002/minf.202200133"
        title="DOI Reference" target="_blank" rel="noopener noreferrer"
      >
        https://doi.org/10.1002/minf.202200133</a>
    </p>

  );
}
