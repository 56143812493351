
import logoCell from '../../images/img-smartphone.png'
import playStore from '../../images/logo-playStore.png'
import './styles.css';

export function SectionSmarthPhone() {
  return (
    <section className='container-smarthphone'>

      <div className='org-divs-info-app'>
        <div className='org-divs-info-app-texts'>
        </div>

        <div className='info-img-cell'>
        </div>

      </div>

    </section>
  );
}
