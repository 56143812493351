import { Header } from './components/Header';
import { Section } from './components/Section';

import molecules from './images/molecules.png';
import file from './images/file.png';
import { TeamSection } from './components/TeamSection';
import { PartnersSection } from './components/PartnersSection';
import { About } from './templates/About';
import { Howtocite } from './templates/Howtocite';

import ufpb from './images/partners/ufpb.png';
import cnpq from './images/partners/CNPq.png';
import chemaxon from './images/partners/chemaxon.png';
import sistematx from './images/partners/sistematx.png';
import { Footer } from './components/Footer';
import {SectionSmarthPhone} from './components/SectionSmarthphone'

import './App.css';
import { Home } from './components/Home';
import { Divider } from './components/Divider';

function App() {

  return (
    <div className="App">

      <div className="container70">
        <Header />

        <Home />

        <Divider horizontal />

        <Section 
          side="right"
          image={molecules} 
          title="About the tool"
          id="about"
          className="web-section"
        >
          <About />
        </Section>

        <Section 
          side="left"
          image={file} 
          title="How to cite"
          id="how-to-cite"
          className="web-section"
        >
          <Howtocite />
        </Section>


       <SectionSmarthPhone/>



      </div>

      <TeamSection />

      <PartnersSection>
        <img src={ufpb} className="ufpb" alt="ufpb logo" />
        <img src={cnpq} className="cnpq" alt="cnpq logo" />
        <img src={chemaxon} className="chemaxon" alt="chemaxon logo" />
        <img src={sistematx} className="sistematx" alt="sistematx logo" />
      </PartnersSection>

      <Footer/>
    </div>
  );
}

export default App;
