/**
* Home
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

import logoInside from '../../images/logo-inside.png';
import { Button } from '../Button';

import './styles.css';

export function Home() {
  return (
    <div className="home">
      <div className="text-container">
        <p className="about">
          AmIActive is a web tool that allows the scientific community
          to obtain biological activities predictions of molecules.
        </p>

        <Button title="Use the tool" redirect="https://amiactive.ccen.ufpb.br/home/" />
      </div>

      <div className='img-logo'>
        <img src={logoInside} alt="" />
      </div>
    </div>
  );
}
