/**
* About Template
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

export function About() {
  return (
    <p>    
      AmIActive manages predictive models already published in indexed 
      scientific articles and makes predictions of molecules the researcher 
      wishes to investigate. 
      <br/>
      <br/>

      It informs the qualitative prediction of the molecules, as well as a 
      probability quantitative value of activity. AmIActive also provides 
      the possibility for the researcher to download predictions in the .csv format
    </p>
  );
}
