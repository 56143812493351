import './styles.css';

export function PartnersSection({ children }) {
  return (
    <div className="team partners">
      <h1>Partners</h1>
      <div className="partners-container">
        {children}
      </div>
    </div>
  );
}
