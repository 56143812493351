import './styles.css';

export function Section({ side, image, title, children, id }) {
  return (
    <section className="section-container">
      
      {
        side == 'left' ? <img src={image} alt="section image" /> : <></>
      }

      <div className={`text-container ${ side == 'right' ? "right-text-container" :  "left-text-container"}`}>
        <h1 id={id} className="title">{title}</h1>
        {children}
      </div>

      {
        side == 'right' ? <img src={image} alt="molecules" /> : <></>
      }
    </section>
  );
}
