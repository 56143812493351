import logo from '../../images/logo.png';
import './styles.css';

export function Footer() {

    return (
        <>

            <footer className='container-footer'>

                    <div className='org-info'>
                        <div className='org-img'>
                            <div className='info-img'>

                                <img src={logo} alt="molpredictx-logo" />
                                <h3>AmIActive</h3>
                            </div>
                        </div>
                        <div className='info-link'>
                            <h3>Options</h3>


                            <a href="#how-to-cite">
                                <p>How to cite</p>
                            </a>
                            <a href="#about">
                                <p>About</p>
                            </a>
                            <a href="#team">
                                 <p>Team</p>
                            </a>
                


                        </div>
                        <div id='contact' className='info-link'>

                            <h3>Contact</h3>
                            <p>Email:mtscotti@gmail.com</p>
            
                        </div>
                    </div>
                    
                    <div className='info-reserved'>

                        <hr />
                        <p>All rights reserved AmIActive - 2024</p>
                    </div>
            </footer>

        </>
    );
}
